define('modules/page-load-animation',[
  'jquery',
  'underscore',
  'TweenMax'
], function($, _, TweenMax){

  var PageLoadAnimation = function(){
    this.$blue = $('#blue');
  };

  PageLoadAnimation.prototype.hide = function()
  {
    TweenMax.to(this.$blue, .67, { top: '100%', ease: Power2.easeInOut });
  };

  PageLoadAnimation.prototype.show = function()
  {
    TweenMax.to(this.$blue, .67, { top: '0', ease: Power2.easeInOut });
  }

  return PageLoadAnimation;
});
