define('modules/lib/waypoint',[
  'jquery',
  'underscore',
  'modules/scroll-manager',
  'modules/transition-manager'
], function($, _, ScrollManager, TransitionManager){

  'use strict';

  var oScrollManager = new ScrollManager(),
      oTransitionManager = new TransitionManager();

  /**
   * Waypoint Contstructor
   *
   * 
   * 
   * @param {Object} opts:
   * {
   *    $el: <jQuery> $el you want to scroll to
   *    offset: <int> any offset that should be added
   *            <string> "40%" percentage of windows height
   *    handler: <fn> your handler that should be called when the element is reached
   * }
   * 
   */
  var Waypoint = function(opts)
  {
    var oOptions = opts || {};

    var defaults = {
      $el: $(null),
      offset: 0,
      handler: function(){}
    };

    oOptions = _.defaults(oOptions, defaults);

    if( oOptions.$el.length === 0 ){
      return;
    }

    this.oOptions = oOptions;
    this.$el = oOptions.$el;
    this.bHasBeenCalled = false;
    this.iWindowHeight = $(window).height();
    this.oScroller = oScrollManager.getScroller();
    this.iOptionOffset = parseInt(this.oOptions.offset, 10);
    this.setOffset(oOptions.offset);

    this.registerBindings();
    this.onScroll();
  };

  Waypoint.prototype.setOffset = function(value)
  {
    var offset = 0;
    if( value.indexOf('%') !== -1 ){
      var percent = parseFloat(value);
      offset = Math.floor(this.iWindowHeight / 100 * percent);
    }else{
      offset = parseInt(value, 10);
    }
    this.iOptionOffset = offset;
  };

  /**
   * register bindings
   * @return {void}
   */
  Waypoint.prototype.registerBindings = function()
  {
    // bind
    oScrollManager.addListener(ScrollManager.EVENT_SCROLL, _.bind(this.onScroll, this));
  };

  Waypoint.prototype.deregisterBindings = function()
  {
    oScrollManager.removeListener(ScrollManager.EVENT_SCROLL, _.bind(this.onScroll, this));
  };

  /**
   * onScroll callback
   * calls the options.handler when a certain scroll position is reached
   * calls this handler only once!
   * 
   * @return {void}
   */
  Waypoint.prototype.onScroll = function()
  {
    var position = this.oScroller.getComputedPosition();

    if( this.bHasBeenCalled || position === undefined ){
      return;
    }

    if( this.iOptionOffset > this.$el.offset().top){
      this.bHasBeenCalled = true;
      this.oOptions.handler(position);
    }
  };

  Waypoint.prototype.destroy = function()
  {
    this.deregisterBindings();
  };

  Waypoint.prototype.getElement = function()
  {
    return this.$el;
  };

  return Waypoint;
});
