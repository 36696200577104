define('tileLayerAni',[
    'jquery',
    'TweenMax'
], function($, TweenMax)
{
    'use strict';
       
    /**
     * Tile Layer Animation class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2015, SOPG GbR
     * 
     * Created: 2015-07-15
     * 
     * @returns {undefined}
     */
    var TileLayerAnimation = function()
    {
        /**
         * Own instance.
         * 
         * @type TileLayerAnimation
         */
        var self = this;
        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'TileLayerAnimation';
                
        /**
         * CSS selectors.
         * 
         * @type Object
         */
        var oSel = {
            layerContainer : '.js-tile-layer-container',
            layer          : '.tile-layer'
        };
        
        /**
         * Settings object,
         * holding all TileLayerAnimation settings.
         * 
         * @type Object
         */
        var oSet = {
            speed : {
                rollIn  : 0.25,
                rollOut : 0.25
            },
            easing : {
                rollIn  : Back.easeOut,
                rollOut : Back.easeOut
            }
        };
                
        
        /**
         * 
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-15
         * 
         * @returns {Object}
         */
        this.initialize = function()
        {
            log('Initializing tile layer animation ...');
                      
            /* Bind the mouse listeners to each tile. */
            $(oSel.layerContainer).each(function()
            {
                self._bindTileMouseListener($(this));
            });
            
            log('Tile layer animation initialized.');
        };
        
        
        /**
         * Binds a mouseenter and mouseleave event listener 
         * to the given jQuery tile object.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-15
         * 
         * @param {jQuery} $tile
         * @returns {undefined}
         */
        this._bindTileMouseListener = function($tile)
        {
            $tile.bind('mouseenter mouseleave', function(oEvnt)
            {
                /* Get the layer inside the tile. */
                var $layer = $tile.find(oSel.layer);
                
                if (0 < $layer.length) {
                    /* Get the position, from where the event was triggered. */
                    var sPos = self._getPosition($tile, oEvnt.pageX, oEvnt.pageY); 
                    /* Handle the event, based on its type. */
                    if ('mouseenter' === oEvnt.type) {
                        self._handleMouseEnter($layer, sPos);
                    } else 
                    if ('mouseleave' === oEvnt.type) {
                        self._handleMouseLeave($layer, sPos);
                    }
                }
            });            
        };
                
        
        /**
         * Handles the mouse entering for the given layer object, 
         * based on the direction that is given in the second argument.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-15
         * 
         * @param {jQuery} $layer
         * @param {string} sFrom
         * @returns {undefined}
         */
        this._handleMouseEnter = function($layer, sFrom)
        {
            /* Define the layer's end position 
            and an empty origin, that gets filled later on, 
            based on the given FROM direction. */
            var oEndPos = { top: 0, left : 0 },
                oOrigin = {};
        
            /* Add the easing, if it's set. */
            if (oSet.easing.rollIn) {
                oEndPos.ease = oSet.easing.rollIn;
            }
            
            /* Fill the origin object, 
            based on the given FROM direction. */
            switch (sFrom) {
                case 'top':
                    oOrigin = { 
                        top    : '-100%', 
                        left   : 0
                    };
                    break;
                case 'right':
                    oOrigin = { 
                        top    : 0, 
                        left   : '100%'
                    };                    
                    break;
                case 'bottom':
                    oOrigin = { 
                        top    : '100%', 
                        left   : 0
                    }; 
                    break;
                case 'left':
                    oOrigin = { 
                        top    : 0, 
                        left   : '-100%'
                    }; 
                    break;
                default: /* left */
                    log('ERROR: From direction is "' + sFrom + '".');
                    break;
            }
            
            /* First, set the layer to the calculated origin. */
            TweenMax.set($layer, oOrigin);
            /* Then, animate it to the defined end position. */
            TweenMax.to($layer, oSet.speed.rollIn, oEndPos);
        };
        
        
        /**
         * Handles the mouse leaving for the given layer object, 
         * based on the direction that is given in the second argument.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-15
         * 
         * @param {jQuery} $layer
         * @param {string} sTo
         * @returns {undefined}
         */        
        this._handleMouseLeave = function($layer, sTo)
        {        
            /* Define an empty destination object, 
            that gets filled later on, 
            based on the given TO direction. */
            var oDestination = {};
            
            /* Fill the destination object, 
            based on the given TO direction. */
            switch (sTo) {
                case 'top':
                    oDestination = {
                        top  : '-100%',
                        left : 0
                    };
                    break;
                case 'right':
                    oDestination = {
                        left : '100%',
                        top  : 0
                    };
                    break;
                case 'bottom':
                    oDestination = {
                        top  : '100%',
                        left : 0
                    };
                    break;
                case 'left':
                    oDestination = {
                        left : '-100%',
                        top  : 0
                    };
                    break;
                default: /* right */
                    log('ERROR: To direction is "' + sTo + '".'); 
                    break;
            }            
            
            /* Add the easing, if it's set. */
            if (oSet.easing.rollOut) {
                oDestination.ease = oSet.easing.rollOut;
            }
            
            /* Animate the layer to the calculated destination. */
            TweenMax.to($layer, oSet.speed.rollOut, oDestination);
        };
        
        
        /**
         * Returns the position, where the given tile element 
         * has been entered or left with the mouse, based on
         * the given mouse event's pageX and pageY values.
         * Returns: "top", "left", "bottom" or "right".
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-15
         * 
         * @param {jQuery} $tile
         * @param {Number} iPageX
         * @param {Number} iPageY
         * @returns {String}
         */
        this._getPosition = function($tile, iPageX, iPageY)
        {
            var iWidth  = $tile.width();
            var iHeight = $tile.height();

            /* Calculate the final X and Y to get an angle to the center of the div from that X and Y. */
            /* Get the X and Y value relative to the center of the tile and normalize it. */
            var iX = (iPageX - $tile.offset().left - (iWidth / 2)) * ((iWidth > iHeight) ? (iHeight / iWidth) : 1 );
            var iY = (iPageY - $tile.offset().top  - (iHeight / 2)) * ((iHeight > iWidth) ? (iWidth / iHeight) : 1 );  
            /* First calculate the angle of the point, add 180 deg to get rid of the negative values. */
            var nAngle = (Math.atan2(iY, iX) * (180 / Math.PI)) + 180;
            /* Divide by 90 to get the quadrant. */
            var nQuadrant = nAngle / 90;
            /* Add 3 and do a modulo by 4 to shift the quadrants to a proper clockwise TRBL (top/right/bottom/left). */
            var iDirection = (Math.round(nQuadrant + 3)) % 4;
            
            switch (iDirection) {
                case 0:
                    return 'top';
                case 1:
                    return 'right';
                case 2:
                    return 'bottom';
                case 3:
                    return 'left';
                default:
                    log('ERROR: Direction value "' + iDirection + '", calculated from angle "' + nAngle + '" in quadrant "' + nQuadrant + '" can\'t be resolved.');
                    break;
            }
        };
                
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * Updated: 2015-07-15
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            if (sLog && 'function' === typeof sLog) {
                sLog(mContent, sName, bForce);
            }
        };
        
        
        /* Initialize 
        and return the returned. */
        return self.initialize();
    };
    
    return TileLayerAnimation;
});
