define('modules/content-service',[
  'jquery',
  'modules/lib/cache-manager'
], function($, CacheManager){
  'use strict';

  var instance;

  var ContentService = function(){
    if(instance){
      return instance;
    }
    instance = this;
    this.oCacheManager = new CacheManager();
    this.apiUrl = '/ajax/content/';
  };

  ContentService.prototype.get = function(state){
    return this.getContent(state);
  };

  ContentService.prototype.getContent = function(state){
    var self = this,
        cache = this._getFromCache(state);

    if( cache ){
      var deferred = $.Deferred();
      deferred.resolve([cache]);
      return deferred.promise();
    }

    return $.ajax({
      type: 'GET',
      url: this.apiUrl + state.id,
      dataType: 'json'
    }).then(function(data){
      self._setCache(state, data);
      return data;
    });
  };

  ContentService.prototype._setCache = function(state, data){
    this.oCacheManager.set('ContentService', state.type + ':' + state.id, data);
  };

  ContentService.prototype._getFromCache = function(state){
    return this.oCacheManager.get('ContentService', state.type + ':' + state.id);
  };

  return ContentService;
});
