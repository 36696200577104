/**
 * define HomeSlider
 * 
 * @provide megageiler homepage slider
 * @provide set options
 * @provide apply self to sub module
 * @param {type} $
 * @returns {undefined}
 * @version 0.1
 * @date 15/07/03
 * @author sh
 */
define(
    'home-slider',[
        'jquery',
        'modules/home-slider/slider.set',
        'modules/home-slider/slider.utils',
        'modules/home-slider/slider.animate',
        'modules/home-slider/slider.event'
    ],
    function($,sliderSet,sliderUtils,sliderAnimate,sliderEvent) 
    {
        'use strict';

        var slider = function(params) 
        {
            params = params || {};

            /**
             * set global instance
             */
            var self = this;
            
            /**
             * define default options
             */
            this.defaultOptions={
                current:0,
                marginY:5,
                breakpoint:{
                    mobile:480,
                    resizeMin:1024,
                    resizeMax:1920
                },
                autoplay:{
                    play:true,
                    duration:3.2,
                    ease:Power0.easeNone,
                    alpha:0.5,
                    factorReset:2.5
                },
                event:{
                    resizeDebounce:10,
                    resizeClass:'onresize',
                    inactiveClass:'inactive',
                    ns:{
                        navigation:'.nav'
                    },
                    swipe:{
                        ignition:0.3
                    }
                },
                animate:{
                    delay:0,
                    blurDuration:0.9,
                    blurDelay:0.3,
                    blurEase:Power1.easeOut,
                    init:{
                        activateClass:'active',
                        mobileClass:'mobile',
                        alphaDurationMobile:0.5
                    },
                    phase:{
                        0:{
                            delay:0,
                            alpha:1,
                            alphaDuration:0.3,
                            alphaEase:Power0.easeNone,
                            alphaDelay:0,
                            duration:0.6,
                            elementDelay:0.2
                        },
                        1:{
                            delay:0,
                            alpha:0,
                            alphaDuration:0.3,
                            alphaEase:Power0.easeNone,
                            alphaDelay:0.2,
                            duration:0.4,
                            elementDelay:0.2,
                            ease:Power2.easeIn
                        },
                        mobile0:{
                            delay:0,
                            alpha:1,
                            alphaDuration:0,
                            alphaEase:Power0.easeNone,
                            alphaDelay:0,
                            duration:0,
                            elementDelay:0,
                            ease:Power0.easeNone,
                            slideDuration:0.6,
                            slideEase:Power2.easeInOut,
                            blurDuration:0,
                            blurDelay:0
                        },
                        mobile1:{
                            delay:0,
                            alpha:0,
                            alphaDuration:0,
                            alphaEase:Power0.easeNone,
                            alphaDelay:0,
                            duration:0,
                            elementDelay:0,
                            ease:Power0.easeNone
                        }
                    }
                },
                pagination:{
                    element:'li',
                    duration:0.5,
                    ease:Power0.easeNone,
                    delay:-0.2
                },
                nav:{
                    alpha:0.6,
                    alphaInactive:0.1,
                    duration:0.2,
                    ease:Power0.easeNone,
                    activeDuration:0.2,
                    activeEase:Power0.easeNone
                }
            };
            
            /**
             * define default selector
             */
            this.defaultSelector={
                el:'.home_slider',
                bg_std:'>.std',
                bg_blur:'>.blur',
                ul:'>ul',
                li:'>li',
                resizeWrapper:'>div',
                slide:'>div>div',
                pagination:'.pagination>ul',
                prev:'.prev',
                next:'.next',
                navigationButton:'button',
                progress:'>.progress'
            };
            
            /**
             * define default data
             */
            this.defaultData={
                y:'y',
                ease:'easing',
                delay:'delay',
                startx:'startx',
                targetx:'targetx',
                endx:'endx',
                height:'height',
                width:'width',
                task:'task',
                blur:'blur',
                type:'type',
                position:'position',
                enableScale:{
                    image:'image'
                },
                goto:'goto'
            };
            
            /**
             * extend options
             */
            this.options=$.extend(true,{},this.defaultOptions,(params.options || {}));
            this.selector=$.extend(true,{},this.defaultSelector,(params.selector || {}));
            this.data=$.extend(true,{},this.defaultData,(params.data || {}));
            
            /**
             * init our main app
             * @returns {undefined}
             */
            this.init=function()
            {
                if( $(this.selector.el).length === 0 ){
                    return;
                }
                $.when(
                        this.initMethods(),
                        this.initOptions(),
                        this.setMatrix(),
                        this.initParams(),
                        this.initElements(),
                        this.initTimeline(),
                        this.initAutoplay(),
                        this.initPagination(),
                        this.initEvents()
                ).then(function(){
                    self.run();
                });
            };
            
            /**
             * init our submodules
             * 
             * @provide deferred
             * @returns {unresolved}
             */
            this.initMethods=function() {
                var deferred=new $.Deferred();
                
                try{
                    sliderSet.apply(this);
                    sliderUtils.apply(this);
                    sliderAnimate.apply(this);
                    sliderEvent.apply(this);
                
                    deferred.resolve();
                } catch(e){console.log(e);}
                
                return deferred.promise();
            };
            
            /**
             * finally run our main app
             * 
             * @returns {undefined}
             */
            this.run=function()
            {
                if(true!==this.requirementsAreMet()) {
                    return;
                }
                
                $.when(
                        this.animateIntro(),
                        this.animateNavigation()
                ).then(
                    function() {
                        self.activateGallery();
                    }
                );
            };
            
            this.init();
        };

        return slider;
    }
);

