define('modules/scroll-manager',[
  'jquery',
  'underscore',
  'modules/lib/event',
  'iscroll',
  'TweenMax'
], function($, _, Event, IScroll, TweenMax)
{

  'use strict';

  var instance;

  /**
   * ScrollManager constructor
   * - ensures a singleton
   */
  var ScrollManager = function()
  {
    if(instance){
      return instance;
    }

    instance = this;

    this.oListeners = {
      'SCROLL': []
    };

    this.aWaypoints = [];
    this.isMobile = Modernizr.touch;

    this.oContentScroller = null;
    this.createContentScroller($('#app'));

    // we kinda have to refresh it in a interval
    // slow connections load images bit by bit
    // to make the site responsive without all images being loaded
    // we call refresh every X seconds
    setInterval( _.bind(this.refresh, this), 2000);
  };

  /**
   * CONSTANTS
   */
  ScrollManager.EVENT_SCROLL = 'SCROLL';
  ScrollManager.AUTO_SCROLL_SPEED = 300;

  /**
   * extend prototype
   */
  _.extend(ScrollManager.prototype, Event.prototype);


    /**_
     * createContentScroller
     * @param  {$el} jQuery element
     * @return {void}
     */
    ScrollManager.prototype.createContentScroller = function($el)
    {
        /* Added 2016-06-29, SOPGSITE-108:
        Do not init IScroll on IE8 (browser-default
        scrolling will be taken. */
        if (!window.app.browser.ie.ie8) {
            
            var iLastTop = 0;
            
            this.oContentScroller = new IScroll($el[0], {
                mouseWheel: true,
                disableMouse: !this.isMobile,
                disablePointer: !this.isMobile,
                scrollbars: true,
                click: this.isMobile,
                keyBindings: true,
                bounce: this.isMobile,
                momentum: this.isMobile,
                interactiveScrollbars: !this.isMobile,
                probeType: 3
            });

            // _callListeners is implemented in event.js
            this.oContentScroller.on('scroll', _.bind(this._callListeners, this, ScrollManager.EVENT_SCROLL));
            this.initNearPointerDetection();
            
            /* Added 2016-12-12, SOPGSITE-184: */
            this.oContentScroller.on('scroll', function(oEv)
            {
                var iTop = Math.abs($('.scroll-wrapper').offset().top);
                
                if (iTop >= iLastTop && iTop > (64 + 46)) {
                    $('#wrapper').addClass('minified');
                } else {
                    $('#wrapper').removeClass('minified');
                }
                
                iLastTop = iTop;
            });
        }
    };
    

    /**
     * Calculates the distance between the given $elem's 
     * center and the given mouse X and Y coords and returns it.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @jira SOPGSITE-111
     * 
     * Created: 2016-06-29
     * 
     * @param {jQuery} $elem
     * @param {Integer} iMouseX
     * @param {Integer} iMouseY
     * @returns {Integer}
     */
    ScrollManager.prototype._calculateDistance = function($elem, iMouseX, iMouseY)
    {
        return Math.floor(
            Math.sqrt(
                Math.pow(iMouseX - ($elem.offset().left + ($elem.width()/2)), 2) 
              + Math.pow(iMouseY - ($elem.offset().top + ($elem.height()/2)), 2)
            )
        );
    };
    
    
    /**
     * Calculates the horizontal distance between the given $elem's 
     * center and the given mouse X and Y coords and returns it.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @jira SOPGSITE-111
     * 
     * Created: 2016-06-29
     * 
     * @param {jQuery} $elem
     * @param {Integer} iMouseX
     * @param {Integer} iMouseY
     * @returns {Integer}
     */
    ScrollManager.prototype._calculateHorzDistance = function($elem, iMouseX, iMouseY)
    {
        return Math.floor(
            Math.sqrt(
                Math.pow(iMouseX - ($elem.offset().left + ($elem.width()/2)), 2)
            )
        );
    };
    
    
    /**
     * Calculates the vertical distance between the given $elem's 
     * top and bottom position and the given mouse X 
     * and Y coords and returns it.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @jira SOPGSITE-111
     * 
     * Created: 2016-06-29
     * 
     * @param {jQuery} $elem
     * @param {Integer} iMouseX
     * @param {Integer} iMouseY
     * @returns {Integer}
     */
    ScrollManager.prototype._calculateVertDistance = function($elem, iMouseX, iMouseY)
    {
        var iDist =  Math.floor(
            Math.sqrt(
                Math.pow(iMouseY - ($elem.offset().top), 2),
              + Math.pow(iMouseY - ($elem.offset().top + ($elem.height())), 2)
            )
        ); 
        
        if (iMouseY >= $elem.offset().top) {
            return iDist - $elem.height();
        } else {
            return iDist;
        }        
    };


    /**
     * Initializes the detection that detects, 
     * that the pointer is near the scrollbar.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @jira SOPGSITE-111
     * 
     * Created: 2016-06-29
     * 
     * @returns {undefined}
     */
    ScrollManager.prototype.initNearPointerDetection = function()
    {
        var self = this;
        var $scrollbar = $('.iScrollIndicator');
            
        if (0 < $scrollbar.length) {

            $(document).mousemove(function(oEvent)
            {
                var iX = oEvent.pageX,
                    iY = oEvent.pageY;
                    
                var iVertDist = self._calculateVertDistance($scrollbar, iX, iY);
                var iHorzDist = self._calculateHorzDistance($scrollbar, iX, iY);

                if (100 > iHorzDist && 50 > iVertDist) {
                    $scrollbar.parent().addClass('mouse-is-near');
                } else {
                    $scrollbar.parent().removeClass('mouse-is-near');
                }
            });
        }       
    };


  /**
   * hideScrollIndicator
   * @return {void}
   */
  ScrollManager.prototype.hideScrollIndicator = function()
  {
    if( this.oContentScroller && this.oContentScroller.indicators && this.oContentScroller.indicators[0] ){
      var $indicator = $(this.oContentScroller.indicators[0].indicator);
      TweenMax.to($indicator, 0.5, {
        alpha: 0
      });
    }
  };

  /**
   * showScrollIndicator
   * @return {void}
   */
  ScrollManager.prototype.showScrollIndicator = function()
  {
    if( this.oContentScroller && this.oContentScroller.indicators && this.oContentScroller.indicators[0] ){
      var $indicator = $(this.oContentScroller.indicators[0].indicator);
      TweenMax.to($indicator, 0.5, {
        alpha: 1
      });
    }
  };

  /**
   * destroys the scroller and removes the vertical scrollbars
   * @return {void}
   */
  ScrollManager.prototype.destroy = function()
  {
    this.oContentScroller.destroy();
    $('.iScrollVerticalScrollbar').remove();
  };

  /**
   * refreshes the height of the scroller
   * @return {void}
   */
  ScrollManager.prototype.refresh = function()
  {
    this.oContentScroller.refresh();
  };

  /**
   * returns the scroller
   * @return {IScroll instance}
   */
  ScrollManager.prototype.getScroller = function()
  {
    return this.oContentScroller;
  };

    /**
     * scrolls to an active anchor
     * @param {string} anchorString the anchor to scroll to
     * @return {void}
     */
    ScrollManager.prototype.scrollToAnchor = function(anchorString)
    {
      var anchor  = anchorString || window.location.hash.replace('#', ''),
          $anchor = $('[data-link-anchor="' + anchor + '"]');
          
      if(anchor && $anchor.length > 0){
          var offset = $anchor.offset().top;
          this.scrollTo(0, -offset, ScrollManager.AUTO_SCROLL_SPEED);
      }
    };
    
  
    /**
     * Scrolls to the 
     * given position (y coord).
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @jira SOPGSITE-125
     * 
     * Created: 2016-07-06
     * 
     * @param {Integer} iPos
     * @returns {undefined}
     */
    ScrollManager.prototype.scrollToPosition = function(iPos)
    {
        var iAbsPos = Math.abs(parseInt(iPos));

        if (0 < iAbsPos) {
            ScrollManager.log('Auto scrolling to ' + iAbsPos + 'px ...');
            this.scrollTo(0, -iAbsPos, ScrollManager.AUTO_SCROLL_SPEED);
            setTimeout(function()
            {
              ScrollManager.log('Auto scrolling finished.');
            }, ScrollManager.AUTO_SCROLL_SPEED);
        } else {
            ScrollManager.log('No position defined to scroll to.');
        }
    };


    /**
     * scrollTo
     * 
     * @param  {int} x
     * @param  {int} y
     * @param  {int} duration in ms
     * @param  {string} easing
     * @return {void}
     */
    ScrollManager.prototype.scrollTo = function(x, y, duration, easing)
    {
      this.oContentScroller.scrollTo(x, y, duration, easing);
    };
    

    /**
    * Logs the given content,
    * by using global sLog() function.
    * 
    * @author Michael Bindig <mbi@sopg.de>
    * 
    * Created: 2015-07-03
    * Updated: 2015-07-15
    * 
    * @param {mixed} mContent
    * @param {Boolean} bForce
    * @returns {undefined}
    */
    ScrollManager.log = function(mContent, bForce)
    {
        if (sLog && 'function' === typeof sLog) {
            sLog(mContent, 'ScrollManager', bForce);
        }
    };

    return ScrollManager;
});
