define('modules/module.ce-customer-fader',[
    'jquery'
], function($)
{
    'use strict';
       
    /**
     * Content Element Customer Fader class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2016-12-02
     * Updated: 2016-12-06
     * 
     * @returns {undefined}
     */
    var CeCustomerFader = function()
    {
        /**
         * Own instance.
         * 
         * @type CeSlider
         */
        var self = this;
        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'CeCustomerFader';
                
        /**
         * Selectors.
         * 
         * @type Object
         */
        var oSel = {
            customerFader : '.customer-fader',
            customerTile  : '.customer-tile',
            customerImage : '.customer-image'
        };
        
        /**
         * Settings.
         * 
         * @type Object
         */
        var oSet = {
            delay      : 1500,
            accelerate : false,
            speed      : {
                fadeIn  : 700, //600
                fadeOut : 700,
                next    : 350
            },
            ease : {
                fadeIn  : Power2.easeIn,
                fadeOut : Power2.easeOut
            }
        };
                
                
        /**
         * Initializes all Customer Faders, 
         * that can be found in the DOM.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-02
         * 
         * @returns {undefined}
         */
        this.initialize = function()
        {
            log('Initializing ...');

            self.initCustomerFaders();         

            log('Initialized.');
            
            return self;
        };
       
       
        /**
         * Queries and initializes
         * all customer fader elements.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-02
         * Updated: 2016-12-06
         * 
         * @returns {undefined}
         */
        this.initCustomerFaders = function()
        {
            $(oSel.customerFader).each(function()
            {
                self.fetchImages($(this), null, true);
            });
        };
        
        
        /**
         * Loads further customer images 
         * into the given fader, based 
         * on the given start index.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-02
         * Updated: 2016-12-06
         * 
         * @param {jQuery} $fader
         * @param {Integer} iStartIndex
         * @returns {undefined}
         */
        this.fetchImages = function($fader, iStartIndex, bInitially)
        {
            if (!iStartIndex || 'undefined' !== typeof iStartIndex) {
                iStartIndex = parseInt(iStartIndex);
            }
            
            $.ajax(
            {
                url      : '/ajax/getCustomerFaderUrls',
                method   : 'POST',
                dataType : 'json',
                data     : {
                    action      : 'getCustomerFaderUrls',
                    start_index : iStartIndex
                },
                complete : function(oResponse)
                {
                    var oObj = oResponse.responseJSON;
                    self.insertImages($fader, oObj.urls, bInitially);
                    setTimeout(function()
                    {
                        var iNext = oObj.next;
                        
                        /* Load only the next 
                        four for mobile widths. */
                        if (768 > $(window).width()) {
                            iNext--;
                        }
                        
                        self.fetchImages($fader, iNext);
                        
                    }, self.getDelay(bInitially));
                }
            });            
        };
        
        /**
         * Returns delay + animation lenght to guarantee
         * stated delay length 
         * 
         * @author Lennart Bergmann <lb@sopg.de>
         * 
         * Created 2018-08-10
         * 
         * @param {bool} bInitially
         * @returns {int} delay
         */
        this.getDelay = function(bInitially)
        {
            if(!bInitially || 'undefined' === typeof bInitially) {
                return oSet.delay + oSet.speed.fadeIn + oSet.speed.fadeOut + oSet.speed.next;
            }
            else {
                return oSet.delay;
            }
        }
        
        /**
         * Inserts the given URLs as images 
         * into the given customer fader.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-02
         * Updated: 2016-12-06
         * 
         * @param {jQuery} $fader
         * @param {Array} aUrls
         * @returns {undefined}
         */
        this.insertImages = function($fader, aUrls, bInitially)
        {            
            self._oAnimation.fade($fader, aUrls, bInitially);
        };
        
        
        /**
         * Image inserting animations.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-02
         * Updated: 2016-12-07
         * 
         * @type Object
         */
        this._oAnimation = 
        {            
            squeeze : function($fader, aUrls, bInitially)
            {                
                $fader.find(oSel.customerTile).each(function(iIdx)
                {
                    var iMinuend = self.getAccelerationMinuend();
                    var $this = $(this);
                    var $image = $this.find(oSel.customerImage);
                    var iTimeout = iIdx * oSet.speed.next - iMinuend;
                    
                    setTimeout(function()
                    {
                        var oTimeline = new TimelineMax({ paused : true });
                        
                        /* Don't fade out old image initially, 
                        because there is no old image. Instead, 
                        set the upcoming image's opacity to 0. */
                        if (!bInitially || 'undefined' === typeof bInitially) {
                            /* Make the image disappear. */
                            oTimeline.to($image, (oSet.speed.fadeOut / 1000), { scale : 0, opacity: 0, ease : oSet.ease.fadeOut });
                        } else {
                            oTimeline.set($image, { opacity : 0 });
                        }
                        
                        /* Change the background image. */
                        oTimeline.call(function()
                        {
                            $image.css('background-image', "url('" + aUrls[iIdx] + "')");
                        });
                        
                        /* Make the image appear again. */
                        oTimeline.to($image, (oSet.speed.fadeIn / 1000), { scale : 1, opacity: 1, ease : oSet.ease.fadeIn });

                        oTimeline.play();
                        
                    }, iTimeout);                    
                });
            },
            fade : function($fader, aUrls, bInitially)
            {                         
                $fader.find(oSel.customerTile).each(function(iIdx)
                {
                    var iMinuend = self.getAccelerationMinuend(iIdx);
                    var $this = $(this);
                    var $image = $this.find(oSel.customerImage);
                    var iTimeout = (!bInitially || 'undefined' === typeof bInitially) 
                                 ? (iIdx * oSet.speed.next - iMinuend) 
                                 : 0;
                                        
                    var oTimeline = new TimelineMax({ paused : true });

                    /* Pause the timeline, 
                    if window loses focus. */
                    window.addEventListener('blur', function() 
                    {
                        oTimeline.pause();
                    }, false);

                    /* Resume the timeline, 
                    if window gets focused again. */
                    window.addEventListener('focus', function() 
                    {
                        oTimeline.resume();    
                    }, false);
                                                            
                    setTimeout(function()
                    {
                        /* Don't fade out old image initially, 
                        because there is no old image. Instead, 
                        set the upcoming image's opacity to 0. */
                        if (!bInitially || 'undefined' === typeof bInitially) {
                            /* Make the image disappear. */
                            oTimeline.to($image, (oSet.speed.fadeOut / 1000), { opacity: 0, ease : oSet.ease.fadeOut });
                        } else {
                            oTimeline.set($image, { opacity : 0 });
                        }
                        
                        /* Change the background image. */
                        oTimeline.call(function()
                        {
                            $image.css('background-image', "url('" + aUrls[iIdx] + "')");    
                        });

                        /* Make the image appear again. */
                        oTimeline.to($image, (oSet.speed.fadeIn / 1000), { opacity: 1, ease : oSet.ease.fadeIn });

                        oTimeline.play();
                        
                    }, iTimeout);
                });
            }
        };
        
        
        /**
         * Returns the acceleration minuend.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-12-06
         * 
         * @param {Number} iFactor
         * @returns {Number}
         */
        this.getAccelerationMinuend = function(iFactor)
        {
            if (oSet.accelerate) {
                return ((iFactor / 0.08) * iFactor)
            }
            
            return 0;
        };
       
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            sLog(mContent, sName, bForce);
        };
        
        
        /* Initialize 
        and return the returned. */
        return self.initialize();
    };
    
    return CeCustomerFader;
});
