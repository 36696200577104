require.config(
{
    waitSeconds : 200,
    //urlArgs     : 'v=' + Date.now(),
    paths       : {
        'jquery'       : 'vendor/jquery-1.x/jquery-1.11.1.min',
        'jquery-ui'    : 'vendor/jquery-ui/jquery-ui.min',
        'jquery-cycle' : 'vendor/jqcycle2/build/jquery.cycle2',
        'jquery-path'  : 'modules/jquery.get-path',
        'jquery-mmenu' : 'vendor/jquery-mmenu/jquery.mmenu.min.all',
        'jquery-mobile': 'vendor/jquery-mobile/jquery.mobile.custom.min',
        'jq_bridget'   : 'vendor/jquery-bridget/jquery.bridget',
        'jq-waypoints' : 'vendor/imakewebthings-waypoints-3.1.1/lib/jquery.waypoints.min',
        'fancybox'     : 'vendor/fancybox/source/jquery.fancybox',
        'TweenLite'    : 'vendor/greensock/src/uncompressed/TweenLite',
        'TweenMax'     : 'vendor/greensock/src/uncompressed/TweenMax',
        'TimelineMax'  : 'vendor/greensock/src/minified/TimelineMax.min',
        'Draggable'    : 'vendor/greensock/src/minified/utils/Draggable.min',
        'Flickity'     : 'vendor/flickity/flickity.pkgd.min',
        'application'  : 'application',
        'iscroll'      : 'vendor/iscroll/build/iscroll-probe',
        'base-slider'  : 'modules/module.base-slider',
        'wp-addcomment': '../../../../../wp/wp-includes/js/comment-reply',
        'text-shorten' : 'modules/sopg.text-shortener',
        'easing'       : 'vendor/greensock/src/uncompressed/easing/EasePack',
        'googleMapper' : 'modules/module.google-mapper',
        'tileLayerAni' : 'modules/module.tile-layer-animation',
        'portfolioSrv' : 'modules/module.portfolio-service',
        'ce-slider'    : 'modules/module.ce-slider',
        'ce-parallax'  : 'modules/module.ce-parallax-image',
        'ce-cust-fade' : 'modules/module.ce-customer-fader',
        'home-slider'  : 'modules/home-slider/slider',
        'underscore'   : 'vendor/underscore-master/underscore-min',
        'particleslider': 'vendor/particleslider'
    },
    shim:
    {
        'iscroll': {
            exports: 'IScroll'
        },
        'particleslider': {
            exports: 'ParticleSlider'
        },
        'ce-slider' : {
            deps : ['jquery']
        },
        'ce-parallax' : {
            deps : ['jquery']
        },
        'ce-cust-fade' : {
            deps : ['jquery']
        },
        'jquery-ui' : {
            deps : ['jquery']
        },
        'jq-bridget' : {
            deps : ['jquery']
        },
        'jquery-path' : {
            deps : ['jquery']
        },
        'jquery-mmenu' : {
            deps : ['jquery']
        },
        'jq-waypoints' : {
            deps : ['jquery'] 
        },
        'jquery-cycle' : {
            deps : ['jquery']
        },
        'base-slider'  : {
            deps : ['jquery-cycle']
        },
        'text-shorten' : {
            deps : ['jquery']
        },
        'fancybox' : {
            deps : ['jquery']
        },           
        'TweenLite' : {
            deps : ['easing']
        },
        'googleMapper' : {
            deps : ['jquery']
        },
        'Flickity' : {
            deps : ['jq_bridget']
        },
        'tileLayerAni' : {
            deps : ['jquery']
        },
        'portfolioSrv' : {
            deps : ['jquery']
        },
        'home-slider':{
            deps:['jquery','TweenMax','underscore','jquery-mobile']
        },
        'Draggable' : {
            deps: ['TweenMax']
        }
    }
});


/**
 * THIS IS THE MAIN ENTRY POINT
 */
require([
    'application',
    'jquery',
    'Flickity',
    'jq_bridget'
], function(Application, jq, Flickity)
{
    'use strict';   

    /* Use jQuery Bridget to combine 
    jQuery Flickity functions with requireJS. */
    jq.bridget('flickity', Flickity);
  
    window._Application = new Application();    
});
define("main", function(){});

