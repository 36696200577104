define('application',[
    'jquery',
    'underscore',
    'TimelineMax', 
    'TweenMax',
    'Draggable',
    
    /* Quoted out 2020-01-09, SOPGSITE-215 */
    //'googleMapper',
    
    'home-slider',
    'ce-slider',
    'tileLayerAni',
    'portfolioSrv',
    'iscroll',
    'modules/transition-manager',
    'modules/scroll-manager',
    'modules/lib/waypoint',
    'modules/lib/waypoint-manager',
    'modules/module.featured-projects',
    'modules/module.pink-star',
    'modules/module.ce-manager',
    'modules/module.ce-parallax-image',
    'modules/module.ce-customer-fader',
    'modules/page-load-animation',
    'modules/content-animation',
    'jquery-mmenu',
    'fancybox',
    'TweenMax',
    'jquery-ui',
    'jquery-path',
    'jq-waypoints',
    'base-slider',
    'wp-addcomment',
    'text-shorten'
], function(
    $,
    _,
    TimelineMax,
    TweenMax,
    Draggable,
    
    /* Quoted out 2020-01-09, SOPGSITE-215 */
    //GoogleMapper,
    
    HomeSlider,
    CeSlider,
    TileLayerAnimation,
    PortfolioService,
    IScroll,
    TransitionManager,
    ScrollManager,
    Waypoint,
    WaypointManager,
    FeaturedProjects,
    PinkStarModule,
    CeManager,
    CeParallaxImage,
    CeCustomerFader,
    PageLoadAnimation,
    ContentAnimation
){
    'use strict';
       
    /**
     * Application class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2020, SOPG
     * 
     * @created 2014-10-30
     * @updated 2020-01-09
     * 
     * @returns {undefined}
     */
    var Application = function()
    {
        /**
         * Own instance.
         * 
         * @type Application
         */
        var self = this;
                                        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'Application';

        var oDate = new Date;
        
        /**
         * Application wide CSS selectors.
         * 
         * Updated: 2015-07-24
         * 
         * @type Object
         */
        this._oSel = {
            global : {
                header : '#header',
                main   : '#app',
                footer : '#footer',
                tchLnk : '.touchable'
            },
            navigation : {
                mobile : {
                    self   : '#menuMobile',
                    menu   : '.mobile-menu',
                    opener : '#mmenuOpener',
                    item   : '.menu-item, .submenu-item'
                }
            },
            page : {
                portfolio : '#portfolio',
                project   : '#project'
            }
        };
        
        /**
         * Application wide settings.
         * 
         * @type Object
         */
        this._oSet = {
            header : {
                stickyClass : 'sticky'
            },
            mobile : {
                breakpoint : 767,
                flac       : 'mobile',
                navigation : {
                    title  : '<a class="website-header-logo" href="' + window.app.url.home + '" title="Startseite"><img src="' 
                           + window.app.url.images + 'SOPG-logo.png" alt="SOPG GbR Logo" width="123" height="28"/></a>',
                    footer : 'beauteous handcrafted websites since 1998 // ©&nbsp;' + oDate.getFullYear(),
                    opened : 'mmenu-opened'
                }
            }
        };
        
        /**
         * GoogleMapper module instance.
         * 
         * @type GoogleMapper
         */
        this._oGoogleMapper = null;
        
        /**
         * TileLayerAnimation module instance.
         * 
         * @type TileLayerAnimation
         */
        this._oTileLayerAnimation = null;
        
        /**
         * PortfolioService module instance.
         * 
         * @type PortfolioService
         */
        this._oPortfolioService = null;
        
        /**
         * Content Element Slider instance.
         * 
         * @type CeSlider
         */
        this._oCeSlider = null;
        
        /**
         * Content Element Manager instance.
         * 
         * @type CeManager
         */
        this._oCeManager = null;
        
        /**
         * Content Element Parallax Image instance.
         * 
         * @type CeParallaxImage
         */
        this._oCeParallaxImage = null;
        
        /**
         * Customer Fader module instance.
         * 
         * @type CeCustomerFader
         */
        this._oCeCustomerFader = null;


        /**
         * Initializes this class 
         * by setting all necessary data 
         * and calling all necessary methods.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * @updated 2020-01-09
         * 
         * @returns {undefined}
         */
        this._initialize = function()
        {
            log('Initializing ...');

            self._oPageloadAnimation = new PageLoadAnimation();
            self._homeSlider = new HomeSlider();
            //self._logoParticle = new LogoParticle();
            self._featuredProjects = new FeaturedProjects();
            self._oPortfolioService = new PortfolioService();
            self._oTransitionManager = new TransitionManager();
            self._oScrollManager = new ScrollManager();
            
            /* Quoted out 2020-01-09, SOPGSITE-215 */
            //self._oGoogleMapper = new GoogleMapper();
            
            self._oTileLayerAnimation = new TileLayerAnimation();
            self._oPinkStar = new PinkStarModule();
            self._oCeManager = new CeManager();
            self._oCeParallaxImage = new CeParallaxImage(self._oScrollManager);
            self._oContentAnimation = new ContentAnimation();
            /* Added 2016-12-02, SOPGSITE-181: */
            self._oCeCustomerFader = new CeCustomerFader();
            /* Added 2016-06-27, SOPGSITE-113:
            Pass the scroll manager, to refresh it, 
            when slideshows are initialized. */
            self._oCeSlider = new CeSlider(self._oScrollManager);
            
            self._setBrowserAndSystemCssClasses();
            self._adaptMobileFlac();
            self._bindWindowResizeHandler();
            self._bindActivateLink();
            self._initMobileMenu();
            self._initializeTransitionBindings();

            self._setPageHeight();

            self._oScrollManager.scrollToAnchor();

            self._oPageloadAnimation.hide();

            log('Initialized.');
        };


        /**
         * Initializes all necessary stuff 
         * after a page transition was made,
         * by adding listeners to the transition manager.
         * 
         * @author Moritz Beller <mb@sopg.de>
         * 
         * Updated: 2016-12-02
         * 
         * @returns {undefined}
         */
        this._initializeTransitionBindings = function()
        {
            /* Init project counters. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(self._featuredProjects.initializeWaypoints, self._featuredProjects)
            );

            /* Init home slider. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                function()
                {
                    if( self._homeSlider.resetAutoplay ){
                        self._homeSlider.resetAutoplay();
                    }
                    
                    self._homeSlider.init();
                }
            );

            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION_CONTENT_LOAD,
                _.bind(self._oScrollManager.refresh, self._oScrollManager)
            );

            /* Init CE Slider. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                self._oCeSlider.initialize
            );
            
            /* Init CE Manager. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(self._oCeManager.initialize, self._oCeManager)
            );            
            
            /* Init CE parallax image. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(self._oCeParallaxImage.initialize, self._oCeParallaxImage)
            );            
            
            /* Added 2016-12-02, SOPGSITE-181: 
            Init CE customer fader. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(self._oCeCustomerFader.initCustomerFaders, self._oCeCustomerFader)
            );    
            
            /* Set page height. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                self._setPageHeight
            );     

            /* Init tile layer. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                this._oTileLayerAnimation.initialize
            );

            /* Init pink star. */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(this._oPinkStar.updateElement, this._oPinkStar)
            );

            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION_COMPLETE,
                _.bind(this._trackPageView, this)
            );
    
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION_COMPLETE,
                this._setCurrentLink
            );

            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                _.bind(this._oContentAnimation.initialize, this._oContentAnimation)
            );
            
            /* Added 2016-12-12, SOPGSITE-184: */
            self._oTransitionManager.addListener(
                TransitionManager.EVENT_TRANSITION,
                function()
                {
                    $('#wrapper').removeClass('minified');
                }
            );
        };
        
        this._setCurrentLink = function(){
            $('a').removeClass('activelink');
            self._bindActivateLink();
            $('a').parent().removeClass('current');
            $('a[href="' + window.location.href + '"]').parent().addClass('current');
        };
        
        this._bindActivateLink = function(){
            $('a').bind('click', function(){
                $(this).addClass('activelink');
            });
        };

        /**
         * tracks the current page
         * @return void
         */
        this._trackPageView = function()
        {
            if( window.ga ){
                ga('set', 'page', window.location.pathname);
                ga('send', 'pageview');
            }
        };
        
        
        /**
         * Binds all window resize handlers.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-07
         * 
         * @returns {undefined}
         */
        this._bindWindowResizeHandler = function()
        {
            log('Binding window resize handlers ...');
            $(window).resize(function()
            {
                log('window.resize detected.');
                self._adaptMobileFlac();
                self._setPageHeight();
            });
            log('Window resize handlers bound.');
        };
        
        
        /**
         * Adds the browser and system CSS classes
         * to the <html> document node.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-06-23
         * 
         * @returns {undefined}
         */
        this._setBrowserAndSystemCssClasses = function()
        {
            /* Adds the .ios CSS class to the <html> 
            document node, if current system is iOS. */
            if (window.app.system.iOS) {
                $('html').addClass('ios');
            }
        };
        
        
        /**
         * Sets the content wrapper height, 
         * if document is less heigh than window.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-09-20
         * 
         * @return {void} 
         */
        this._setPageHeight = function()
        {
            var iWindowHeight = $(window).height();
            var iHeaderHeight = $('#header').height();
            var iFooterHeight = $('#footer').height();
            
            var $contentWrapper = $('.content-wrapper');
            
            if ($contentWrapper.height() + iFooterHeight + iHeaderHeight < iWindowHeight ) {
                var iNewHeight = iWindowHeight - (iHeaderHeight + iFooterHeight);
                TweenMax.to($contentWrapper, 0.3, { height : iNewHeight });
            }
        };
        
        
        /**
         * Adds/removes a mobile CSS class 
         * to HTML document node.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-07
         * 
         * @returns {undefined}
         */
        this._adaptMobileFlac = function()
        {
            log('Adapting mobile flac ...');
            var iWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            
            if (self._oSet.mobile.breakpoint < iWidth) {
                $('html').removeClass(self._oSet.mobile.flac);
                log('Mobile flac removed.');
            } else {
                $('html').addClass(self._oSet.mobile.flac);
                log('Mobile flac added.');
            }
        };
        
        
        /**
         * Initializes the mobile menu.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-06
         * Updated: 2016-09-20
         * 
         * @returns {undefined}
         */
        this._initMobileMenu = function()
        {
            log('Initializing mobile menu ...');
            var oArgs = {
                "extensions": [
                    "border-full",
                    "effect-slide-menu",
                    "effect-slide-panels-0",
                    "theme-dark"
                ],
                "navbar": {
                    "title": self._oSet.mobile.navigation.title
                },
                "navbars": [
                {
                    "position": "top"
                },
                {
                    "position" : "bottom",
                    "content"  : [
                        '<a>' + self._oSet.mobile.navigation.footer + '</a>'
                    ]
                }]
            };
            
            /* Initialize jQuery mmenu. */
            var $mobileMenuElem = $(self._oSel.navigation.mobile.self);
            $mobileMenuElem.mmenu(oArgs);
                    
            /* Adde 22016-09-20, SOPGSITE-128: */
            var oMmenuApi = $mobileMenuElem.data('mmenu');
            $mobileMenuElem.find(self._oSel.navigation.mobile.item).click(function()
            {
                oMmenuApi.close();
            });
                
            /* Added 2015-08-10:
            Handle mobile menu 
            appearance for IE8. */
            if (isIe(8)) {
                /**
                 * Callback function, 
                 * when mobile menu 
                 * or header is clicked.
                 * 
                 * @author Michael Bindig <mbi@sopg.de>
                 * 
                 * Created: 2015-08-10
                 * 
                 * @type fn
                 */
                var fnCallback = function()
                {
                    setTimeout(function(){
                        var $body = $('body');                
                        if (!$mobileMenuElem.hasClass('mm-opened')) {
                            $body.removeClass(self._oSet.mobile.navigation.opened);
                        } else {
                            $body.addClass(self._oSet.mobile.navigation.opened);
                        }
                    }, 500);
                };

                $(self._oSel.navigation.mobile.opener).click(fnCallback);
                $(self._oSel.navigation.mobile.self).click(fnCallback);
                $('#mm-blocker').click(fnCallback);
                $('#header').click(fnCallback);
                log('Mobile menu adapted for Internet Explorer 8.');
            }
            
            log('Mobile menu initialized.');
        };
        
        /**
         * Binds click handlers to the comment form, 
         * which repair the comment form, after WordPress breaks it.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-12-08
         * 
         * @returns {undefined}
         */
        this._bindCommentFormRepair = function()
        {
            jQuery('#cancel-comment-reply-link').on('click', function()
            {
                window.setTimeout(function()
                {
                    if (0 === jQuery('#wp-temp-form-div').length) {
                        var $h2 = jQuery('#respond').next('h2');
                        jQuery('#respond').parent().prepend($h2);
                        jQuery('#respond').find('h3').removeClass('shown');
                    }
                }, 50);
            });
            
            jQuery('.comment-reply-link').on('click', function()
            {
                jQuery('#commentArea').addClass('comment-reply');
            });
        };
                
        
        /**
         * Provides placeholder support for IE9-.
         * 
         * @author: Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-17
         * 
         * @returns {undefined}
         */
        this._pretendPlaceholderSupportForIe = function()
        {
            var sActivePlaceholderClass = 'active-placeholder';
            
            if ((true === window.app.browser.ie.ie) 
            && (false === window.app.browser.ie.ie10)) {
                jQuery('#commentArea input').each(function(iIdx)
                {
                    var $elem = jQuery(this);
                    if (undefined !== $elem.attr('placeholder')) {
                        var active = document.activeElement;
                        $elem.focus(function()
                        {
                            if ((jQuery(this).attr('placeholder') !== '')
                            && (jQuery(this).val() === jQuery(this).attr('placeholder'))) {
                                jQuery(this).val('').removeClass(sActivePlaceholderClass);
                            }
                        }).blur(function()
                        {
                            if ((jQuery(this).attr('placeholder') !== '')
                            && (((jQuery(this).val() === '') 
                               ||
                               (jQuery(this).val() === jQuery(this).attr('placeholder'))))
                            ) {
                                jQuery(this).val(jQuery(this).attr('placeholder')).addClass(sActivePlaceholderClass);
                            }
                        });
                        $elem.blur();
                        $(active).focus();
                        $elem.parents('form').submit(function()
                        {
                            jQuery(this).find(sActivePlaceholderClass).each(function()
                            {
                                jQuery(this).val(''); 
                            });
                        });
                    }
                });
            }
        };
        
        
        /**
         * Adds additional browser CSS classes to the HTML node.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-17
         * 
         * @returns {undefined}
         */
        this._setAdditionalBrowserCssClasses = function()
        {
            if (true === window.app.browser.ie.ie10) {
                jQuery('html').addClass('ie').addClass('ie10');
            }
        };
                        
        
        /**
         * Calls all necessary methods
         * to finalize the comment area.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-12-08
         * 
         * @returns {undefined}
         */
        this._finalizeCommentArea = function()
        {
            self._removeUnnecessaryCommentFormContent();
            self._pretendPlaceholderSupportForIe();            
            self._bindCommentFormRepair();
        };
        
        
        /**
         * Removes unnecessary comment form content and sets CSS classes.
         * 
         * @returns {undefined}
         */
        this._removeUnnecessaryCommentFormContent = function()
        {
            var fnRemover = function()
            {
                var $formArea = jQuery('#commentFormArea');
                if (0 < $formArea.length) {
                    var $flac = $formArea.find('#wp-temp-form-div');
                    if (0 < $flac.length) {
                        jQuery('#reply-title').addClass('shown');
                    } else {
                        jQuery('#reply-title').removeClass('shown');
                    }
                }
            };
            
            fnRemover();
            
            jQuery('.comment-reply-link').click(fnRemover);
        };
        
        
        /**
         * Returns TRUE, if mobile mode,
         * otherwise FALSE.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-20
         * Updated: 2014-11-21
         * 
         * @type Boolean
         */
        this._isMobile = function()
        {
            return jQuery('html').hasClass(oSettings.mobileClass);
        };
        
        
        this._initFlexibleMobileHeader = function(a, b)
        {
            console.log('------------>');
            console.log(a);console.log(b);
            console.log('<------------');
        };
        
        
        /**
         * Initializes the sticky header on page scroll,
         * by adding or removing CSS class "sticky".
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-02
         * 
         * @returns {$.Promise}
         */
        this._initStickyHeader = function()
        {            
            var $D = $.Deferred();
            sLog('Initializing sticky header ...');
            var oSel = self._oSel.global;
            var $header = $(oSel.header);
            var $main = $(oSel.main);
            var sSticky = self._oSet.header.stickyClass;
            
            /**
             * Checks the window's scroll position
             * and adds or removes the sticky CSS classes.
             * 
             * @author Michael Bindig <mbi@sopg.de>
             * 
             * Created: 2015-04-20
             * 
             * @returns {undefined}
             */
            function scrollCallback()
            {
                var oWindow = $(window);
                if (oWindow.scrollTop() > 1) {  
                    $header.addClass(sSticky);
                    $main.addClass(sSticky);
                    sLog('Sticky class added.');
                } else {
                    $header.removeClass(sSticky);
                    $main.removeClass(sSticky);
                    sLog('Sticky class removed.');
                }
            }
            
            /* Bind the scroll listener. */
            $(window).scroll(scrollCallback);
            /* Call the scroll callback function once initially. */
            scrollCallback();            
            
            sLog('Sticky header initialized.');
            $D.resolve();
            
            return $D.promise();
        };
        
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            sLog(mContent, sName, bForce);
        };        
        
        
        /* Finally, 
        initialize the Application. */
        self._initialize();
    };
    
    return Application;
    
});
