define('modules/lib/history-manager',[
  'underscore'
], function(_){
  'use strict';

  var instance = null;

  /**
   * HistoryManager constructor
   * - ensures singleton
   */
  var HistoryManager = function()
  {
    if(instance){
      return instance;
    }
    instance = this;

    // get initial state from global
    this._linkState = _.extend(window.app.linkState || {}, {
      _pushId: history.state && history.state._pushId ? history.state._pushId : 1
    });

    // array holds functions which will be called on popstate
    this._aListeners = [];

    // shortcut if the browser supports the history
    this._supportsHistory = window.history && window.history.pushState;

    // set initial state
    if(this._supportsHistory){
      history.replaceState(this._linkState, null);
    }

    // register popstate callback
    $(window).on('popstate', _.bind(this._change, this));
  };

  /**
   * Event Constants
   * @type const
   */
  HistoryManager.EVENT_FORWARD = 'FWD';
  HistoryManager.EVENT_BACKWARD = 'BWD';

  /**
   * _change: called on popstate event
   * - calls all listeners
   * 
   * @param  {event} e
   * @return {void}
   */
  HistoryManager.prototype._change = function(e)
  {
    var state = e.originalEvent.state,
        direction = state._pushId < this._linkState._pushId ? HistoryManager.EVENT_BACKWARD : HistoryManager.EVENT_FORWARD;

    // update the internal state
    this._linkState._pushId = state._pushId;

    _.each(this._aListeners, function(func){
      func.call(this, direction, state);
    });
  };

  /**
   * checks if the desired state is the same as the current state
   * @param  {mixed}  id
   * @return {Boolean}
   */
  HistoryManager.prototype.isCurrentState = function(id)
  {
    if( !this._supportsHistory ) return;
    if( window.history.state && window.history.state.id === id ){
        return true;
    }
    return false;
  };

  /**
   * push: push a new state into history stack
   * 
   * @param  {Object} state
   * @param  {string} url
   * @return {void}
   */
  HistoryManager.prototype.push = function(state, url)
  {
    if(!this._supportsHistory) return;
    _.extend(state, {
      _pushId: ++this._linkState._pushId
    });
    window.history.pushState(state, null, url);
  };

  /**
   * persists the scroll state (y offset)
   * so that 
   * @param Number scroll
   */
  HistoryManager.prototype.setScrollState = function(scroll)
  {
    if(!this._supportsHistory) return;
    var state = window.history.state;
    if( !state ) return;
    state.scroll = scroll;
    window.history.replaceState(state, "", window.location);
  };

  /**
   * returns the scroll state for the current state
   * @return Number
   */
  HistoryManager.prototype.getScrollState = function()
  {
    var state = window.history.state;
    if( !state ) return;
    return window.history.state.scroll;
  };

  /**
   * onChange. call this with a function as param.
   * the functions will be invoked when the history changes
   * 
   * @param  {function} func
   * @return {void}
   */
  HistoryManager.prototype.onChange = function( func )
  {
    this._aListeners.push(func);
  };

  HistoryManager.prototype.supportsHistory = function()
  {
      return this._supportsHistory;
  };

  return HistoryManager;
});

