/**
 * define sliderAnimate
 * 
 * @provide animate elements
 * @param {type} $
 * @returns {undefined}
 * @version 0.1
 * @date 15/07/03
 * @author sh
 */
define(
    'modules/home-slider/slider.animate',[
        'jquery'
    ],
    function($) 
    {
        'use strict';

        var sliderAnimate = function() 
        {
            /**
             * set global instance
             */
            var self = this;
            
            /**
             * render given element
             * 
             * @returns {undefined}
             */
            this.triggerAnimate=function()
            {
                if(this.goto===this.current) {
                    return;
                }
                
                this.busy=true;
                
                if(this.goto>this.iSlidesIndexed) {
                    this.goto=0;
                }
                
                if(this.goto<0) {
                    this.goto=this.iSlidesIndexed;
                }
                
                //check if tl exists, create one if not
                this.device=(true===this.isMobile) ? 'mobile' : 'desktop';
                if(true!==this.oTimeline[this.device][this.current].hasOwnProperty(this.goto)) {
                    this.appendNewTimeline(this.current,this.goto);
                }
                this.oTimeline[this.device][this.current][this.goto].seek(0).invalidate().restart();
                
                //trigger pagination
                this.animatePagination();
                
                //trigger nav
                this.animateNavigation();
                
                //finally set current to goto
                this.current=this.goto;
            };
            
            /**
             * render given element
             * 
             * @returns {undefined}
             */
            this.triggerAnimateTouch=function()
            {
                this.busy=true;
                
                this.oTimeline[this.device][this.current][this.goto].play();
                
                //trigger pagination
                this.animatePagination();
                
                //trigger nav
                this.animateNavigation();
                
                //finally set current to goto
                this.current=this.goto;
            };
            
            /**
             * create start animation
             * 
             * @provide deferred
             * @returns {unresolved}
             */
            this.animateIntro=function()
            {
                var deferred=new $.Deferred;
                
                var mixedPhase=0;
                //mark slider as mobile if given
                if(true===this.isMobile) {
                    this.$el.addClass(this.options.animate.init.mobileClass);
                    mixedPhase='mobile0';
                }
                
                var tl=new TimelineMax({
                    delay:(true===this.isMobile) ? 0 : this.options.animate.delay,
                    onStart:function() {
                        if(self.resized===true) {
                            this.oSlides[this.current].li.removeClass(this.options.event.resizeClass);
                        }
                    },
                    onComplete:function() {
                        deferred.resolve();
                        self.playNext();
                    }
                });
                tl.addLabel('init');
                
                //I) append slide itself to timeline
                tl.add(TweenMax.set(this.$ul,{
                    x:(this.iWidth *this.goto *-1),
                    force3D:'auto'
                }));
                tl.add(TweenMax.to(this.oSlides[this.goto].li,((true===this.isMobile) ? this.options.animate.init.alphaDurationMobile : 0.01),{autoAlpha:1,ease:Power0.easeNone}));
                
                //II) append blur img to timeline
                tl.add(this.getTweenBlur(this.oSlides[this.current].li,mixedPhase));
                
                //III) append elements to timeline
                tl.addLabel('target');
                var fDelay=this.options.animate.phase[mixedPhase].delay;
                for(var i=0;i<this.oSlides[this.current].length;i++) {
                    var el=this.oSlides[this.current].el.eq(i);
                    
                    //create label to hook both element tweens
                    fDelay+=((true===this.isMobile) ? 0 : el.data(this.data.delay));
                    tl.addLabel('dynlabel' + i,'target+=' + fDelay);
                    
                    var oTween=this.getTweenElement(el,mixedPhase);
                    tl.add(oTween[0],'dynlabel' + i);
                    tl.add(oTween[1],'dynlabel' + i);
                }
                
                return deferred.promise();
            };
            
            /**
             * animate desktop
             * 
             * @param {int} iCurrent
             * @param  {int} iGoto
             * @returns {TimelineMax}
             */
            this.animateDesktop=function(iCurrent,iGoto)
            {
                var tl=new TimelineMax({
                    delay:this.options.animate.delay,
                    paused:true,
                    onStart:function() {
                        if(self.resized===true) {
                            self.oSlides[iGoto].li.removeClass(self.options.event.resizeClass);
                        }
                    },
                    onComplete:function() {
                        self.busy=false;
                        self.busyTouch=false;
                        self.playNext();
                    },
                    onReverseComplete:function() {
                        self.busy=false;
                        self.busyTouch=false;
                    }
                });
                tl.addLabel('init');
                
                //I) append elements of current to timeline
                var fDelay=this.options.animate.phase[1].delay;
                for(var i=0;i<this.oSlides[iCurrent].length;i++) {
                    var el=this.oSlides[iCurrent].el.eq(i);
                    
                    //create label to hook both element tweens
                    fDelay+=el.data(this.data.delay);
                    tl.addLabel('dynlabel' + i,'init+=' + fDelay);
                    
                    var oTween=this.getTweenElement(el,1);
                    tl.add(oTween.set1,'init');
                    tl.add(oTween[0],'dynlabel' + i);
                    tl.add(oTween[1],'dynlabel' + i);
                }
                
                //II) append slide itself to timeline
                //NB: TweenMax.set is triggered at timeline start for some reason, so use callback here
                tl.addCallback(function(){
                    TweenMax.to(self.oSlides[iCurrent].li,0,{autoAlpha:0});
                    TweenMax.to(self.$ul,0,{
                        x:(self.iWidth *iGoto *-1),
                        force3D:'auto'
                    });
                    TweenMax.to(self.oSlides[iGoto].li,0,{autoAlpha:1});
                });
                
                tl.addLabel('target');
                
                //II) append blur img to timeline
                tl.add(this.getTweenBlur(this.oSlides[iGoto].li));
                
                //III) append elements of goto to timeline
                var fDelay=this.options.animate.phase[0].delay;
                for(var i=0;i<this.oSlides[iGoto].length;i++) {
                    var el=this.oSlides[iGoto].el.eq(i);
                    
                    //create label to hook both element tweens
                    fDelay+=el.data(this.data.delay);
                    tl.addLabel('dynlabel_p1_' + i,'target+=' + fDelay);
                    
                    var oTween=this.getTweenElement(el);
                    tl.add(oTween.set0,'init');
                    tl.add(oTween[0],'dynlabel_p1_' + i);
                    tl.add(oTween[1],'dynlabel_p1_' + i);
                }
                
                return tl;
            };
            
            /**
             * animate mobile
             * 
             * @returns {undefined}
             */
            this.animateMobile=function(iCurrent,iGoto)
            {
                var tl=new TimelineMax({
                    delay:this.options.animate.delay,
                    paused:true,
                    onStart:function() {
                        if(self.resized===true) {
                            self.oSlides[iGoto].li.removeClass(self.options.event.resizeClass);
                        }
                        //reset current element, i.e. last current
                        for(var i=0;i<self.oSlides[iGoto].length;i++) {
                            self.setStart(self.oSlides[iGoto].el.eq(i));
                        }
                    },
                    onComplete:function() {
                        self.busy=false;
                        self.busyTouch=false;
                        self.playNext();
                    },
                    onReverseComplete:function() {
                        self.busy=false;
                        self.busyTouch=false;
                    }
                });
                
                //we have to add init, otherwise tl will last for some reason very long
                tl.addLabel('init');
                
                //I) append elements of current to timeline
                for(var i=0;i<this.oSlides[iCurrent].length;i++) {
                    var oTween=this.getTweenElement(this.oSlides[iCurrent].el.eq(i),'mobile1');
                    tl.add(oTween[0],'init');
                    tl.add(oTween[1],'init');
                }
                
                //II) append slide itself to timeline
                tl.add(TweenMax.set(self.oSlides[iGoto].li,{autoAlpha:1}));
                var oTweenUl=TweenMax.fromTo(self.$ul,(Math.abs(iCurrent -iGoto) *this.options.animate.phase.mobile0.slideDuration),
                {
                    x:(self.iWidth *iCurrent *-1)
                },
                {
                    x:(self.iWidth *iGoto *-1),
                    force3D:'auto',
                    ease:this.options.animate.phase.mobile0.slideEase,
                    immediateRender:false
                });
                tl.add(oTweenUl);
                this.oTweenElement.mobile.push({tween:oTweenUl,current:iCurrent,goto:iGoto});
                
                //III) append elements of goto to timeline
                for(var i=0;i<this.oSlides[iGoto].length;i++) {
                    var oTween=this.getTweenElement(this.oSlides[iGoto].el.eq(i),'mobile0');
                    tl.add(oTween[0],'init');
                    tl.add(oTween[1],'init');
                }
                
                return tl;
            };
            
            /**
             * animate pagination
             * 
             * @provide toggle pagination
             * @returns {undefined}
             */
            this.animatePagination=function()
            {
                var iCurrent=this.current;
                var iGoto=this.goto;
                var fCurrent=0;
                var fGoto=(this.options.pagination.delay || 0) *1000;
                if(fGoto<0) {
                    fCurrent=fGoto *-1;
                    fGoto=0;
                }
                
                window.setTimeout(function(){
                    self.$pagination[iCurrent].removeClass('active');
                },fCurrent);
                window.setTimeout(function(){
                    self.$pagination[iGoto].addClass('active');
                },fGoto);
            };
            
            /**
             * animate navigation
             * 
             * @returns {undefined}
             */
            this.animateNavigation=function()
            {
                /**
                 * @see SOPGSITE-45: disable inactive marking
                 */
                return;
                
                switch(this.goto) {
                    case 0:
                        this.$prevWrapper.addClass(this.options.event.inactiveClass);
                        this.$nextWrapper.removeClass(this.options.event.inactiveClass);
                        break;
                    
                    case this.iSlidesIndexed:
                        this.$nextWrapper.addClass(this.options.event.inactiveClass);
                        this.$prevWrapper.removeClass(this.options.event.inactiveClass);
                        break;
                    
                    default:
                        this.$prevWrapper.removeClass(this.options.event.inactiveClass);
                        this.$nextWrapper.removeClass(this.options.event.inactiveClass);
                        break;
                }
            };
            
            /**
             * get tween background blur for given element
             * 
             * @param {object} el
             * @returns {object}
             */
            this.getTweenBlur=function(el,mixedPhase)
            {
                var oTween;
                var oConfig=this.options.animate;
                if(typeof mixedPhase!=='undefined') {
                    oConfig=this.options.animate.phase[mixedPhase];
                }
                
                if(el.data(this.data.blur)===1) {
                    oTween=TweenMax.to(this.$bgBlur,oConfig.blurDuration,{
                        autoAlpha:1,
                        ease:oConfig.blurEase,
                        delay:oConfig.blurDelay
                    });
                } else {
                    oTween=TweenMax.to(this.$bgBlur,oConfig.blurDuration,{
                        autoAlpha:0,
                        ease:Power0.easeNone,
                        delay:oConfig.blurDelay
                    });
                }
                
                return oTween;
            };
            
            /**
             * get tween element for given element
             * 
             * @provide returns indexed object assigned single tweens
             * @param {object} el
             * @param {mixed} mPhase
             * @returns {object}
             */
            this.getTweenElement=function(el,phase)
            {
                var oTween={};
                
                var mPhase=phase || 0;
                
                //set dynamic keys
                var oDynamicKey={};
                
                switch(mPhase) {
                    case 1:
                    case 'mobile1':
                        oDynamicKey.startTarget='renderEnd';
                        oDynamicKey.startx=this.data.targetx;
                        oDynamicKey.endTarget='end';
                        oDynamicKey.targetX=this.data.endx;
                        oDynamicKey.ease=this.options.animate.phase[1].ease;
                        break;
                    
                    case 0:
                    case 'mobile0':
                    default:
                        oDynamicKey.startTarget='renderTarget';
                        oDynamicKey.startx=this.data.startx;
                        oDynamicKey.endTarget='target';
                        oDynamicKey.targetX=this.data.targetx;
                        oDynamicKey.ease=el.data(this.data.ease);
                        break;
                }
                
                //add set tween on desktop only
                if(mPhase===0) {
                    oTween.set0=TweenMax.set(el,{
                        x:this.getX(el,this.data.startx),
                        scale:this.getScale(el),
                        force3D:'auto',
                        autoAlpha:0,
                        immediateRender:false
                    });
                } else if(mPhase===1) {
                    oTween.set1=TweenMax.set(el,{
                        x:this.getX(el,this.data.targetx),
                        scale:this.getScale(el),
                        force3D:'auto',
                        immediateRender:false
                    });
                }
                
                oTween[0]=TweenMax.to(el,this.options.animate.phase[mPhase].alphaDuration,{
                    autoAlpha:this.options.animate.phase[mPhase].alpha,
                    ease:this.options.animate.phase[mPhase].alphaEase,
                    delay:this.options.animate.phase[mPhase].alphaDelay,
                    immediateRender:false
                });
            
                oTween[1]=TweenMax.to(el,this.options.animate.phase[mPhase].duration,{
                        scale:this.getScale(el),
                        x:this.getX(el, oDynamicKey.targetX),
                        delay:this.options.animate.phase[mPhase].elementDelay,
                        ease:oDynamicKey.ease,
                        force3D:'auto',
                        immediateRender:false,
                        onStart:function(el) {
                            //mark task as rendering
                            el.data(self.data.task,oDynamicKey.startTarget);
                        },
                        onComplete:function(el) {
                            //mark task as target on complete
                            el.data(self.data.task,oDynamicKey.endTarget);
                        },
                        onCompleteParams:[el],
                        onStartParams:[el]
                });
                
                //store all tweens that need update on resize event
                switch(mPhase) {
                    case 1:
                        this.oTweenElement.set1.push({el:el,tween:oTween.set1});
                    case 'mobile1':
                        this.oTweenElement.phase1.push({el:el,tween:oTween[1]});
                        break;
                    
                    case 0:
                        this.oTweenElement.set0.push({el:el,tween:oTween.set0});
                    case 'mobile0':
                    default:
                        this.oTweenElement.phase0.push({el:el,tween:oTween[1]});
                        break;
                }
                
                return oTween;
            };
            
            /**
             * init all common timelines
             * 
             * @returns {undefined}
             */
            this.initTimeline=function()
            {
                if(this.iSlides<=1) {
                    return;
                }
                
                this.oTimeline={
                    desktop:{},
                    mobile:{}
                };
                
                /**
                 * this hold all tweens that needs update on resize event based by phase:
                 * 
                 * phase0: set tweens to target
                 * pahse1: set tweens to end
                 */
                this.oTweenElement={
                    phase0:[],
                    phase1:[],
                    set0:[],
                    set1:[],
                    mobile:[]
                };
                
                var iPrev,iNext;
                for(var i=0;i<this.iSlides;i++) {
                    iPrev=i-1;
                    if(iPrev<0) {
                        iPrev=this.iSlidesIndexed;
                    }
                    iNext=i+1;
                    if(iNext>this.iSlidesIndexed) {
                        iNext=0;
                    }
                    
                    this.appendNewTimeline(i,iPrev);
                    this.appendNewTimeline(i,iNext);
                }
            };
            
            /**
             * append mobile & desktop timeline for given item
             * 
             * @param {int} iCurrent
             * @param {int} iGoto
             * @returns {undefined}
             */
            this.appendNewTimeline=function(iCurrent,iGoto)
            {
                //append desktop timeline
                if(false===this.oTimeline.desktop.hasOwnProperty(iCurrent)) {
                    this.oTimeline.desktop[iCurrent]={};
                }
                this.oTimeline.desktop[iCurrent][iGoto]=this.animateDesktop(iCurrent,iGoto);
                
                //append mobile timeline
                if(false===this.oTimeline.mobile.hasOwnProperty(iCurrent)) {
                    this.oTimeline.mobile[iCurrent]={};
                }
                this.oTimeline.mobile[iCurrent][iGoto]=this.animateMobile(iCurrent,iGoto);
            };
            
            /**
             * init autoplay
             * 
             * @returns {undefined}
             */
            this.initAutoplay=function()
            {
                if(true!==self.autoplay) {
                    return;
                }
                if( this.$progress ){
                    TweenMax.set(this.$progress,{autoAlpha:this.options.autoplay.alpha});
                    
                    //build timeline
                    this.oAutoplay=new TimelineMax({paused:true});
                    this.oAutoplay.add(TweenMax.to(this.$progress,this.options.autoplay.duration,{
                        width:'100%',
                        ease:this.options.autoplay.ease
                    }));
                    this.oAutoplay.add(TweenMax.to(self.$progress,0.3,{autoAlpha:0}));
                    this.oAutoplay.add(TweenMax.set(self.$progress,{width:0}));
                }
                
            };
            
            /**
             * reset autoplay
             * 
             * @provide cleartimeout, reverse progress layer
             * @returns {undefined}
             */
            this.resetAutoplay=function()
            {
                if(true!==this.autoplay) {
                    return;
                }
                
                this.oAutoplay.timeScale(this.options.autoplay.factorReset).reverse();
                window.clearTimeout(this.iTimer);
                this.autoplay=false;
            };
            
            /**
             * trigger next item
             * 
             * @provide trigger resetAutoplay() when triggered manually meanwhile
             * @returns {undefined}
             */
            this.playNext=function()
            {
                if(this.autoplay!==true) {
                    return;
                }
                
                this.oAutoplay.restart();
                
                this.iTimer=window.setTimeout(function(){
                    if(true!==self.autoplay) {
                        self.resetAutoplay();
                        return;
                    }
                    
                    self.goto++;
                    self.triggerAnimate();
                },(this.options.autoplay.duration *1000));
            };
            
            /**
             * set gallery active
             * 
             * @returns {undefined}
             */
            this.activateGallery=function()
            {
                if(this.iSlides<=1) {
                    return;
                }
                
                //mark slider as active
                this.$el.addClass(this.options.animate.init.activateClass);
                
                //show pagination
                TweenMax.to(this.$paginationWrapper,this.options.pagination.duration,{
                    autoAlpha:1,
                    ease:this.options.pagination.ease
                });
                
                //show navigation optional
                if(true===this.bHoverOnInit) {
                    this.$el.trigger('mouseenter' + ((typeof this.options.event.ns.navigation === 'string') ? '.' + this.options.event.ns.navigation : null));
                } else if(true===this.isTouch) {
                    TweenMax.to([self.$prev,self.$next],self.options.nav.duration,{
                        autoAlpha:self.options.nav.alpha,
                        ease:self.options.nav.ease
                    });
                }
                
                //console.log('[HomeSlider] done',this);
            };
           
        };

        return sliderAnimate;
    }
);

